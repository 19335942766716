<template>
  <div class="dashboard-card">
    <div class="dashboard-card-title">
      <h5>Your kits are listed below.</h5>
      <p>
        You can upload your raw data files for each of your previous kits. Once all the files are uploaded, click
        the “Upgrade” button on the bottom right. 
      </p>
    </div>
    <div class="kit-list">
      <div class="kit-item" v-for="item in kitList" :key="item.id">
        <h5>{{ item.resultTitle }}</h5>
        <div>
          <button class="lab-btn lab-btn-success ml-1" @click="openUpload(item)" v-if="!hasUploadedFile(item)">
            <b-spinner v-if="item.isUploading" small> </b-spinner>
            Upload Raw Data
          </button>

          <span v-if="hasUploadedFile(item)" class="file-uploaded">
            File Uploaded <CircleCheckIcon />
          </span>
          <span v-if="hasUploadedFile(item)" class="file-delete" @click="deleteUploadedFile(item)">
            Delete
          </span>
        </div>
      </div>
      <span class="lab-badge lab-danger" v-show="kitList.length === 0">No kits are available to be updated.</span>
    </div>
    <div class="footer" v-show="kitList.length">
      <button class="lab-btn lab-btn-success ml-1" @click="startUploading()"> 
        <spinner-loader /> Upgrade 
      </button>
    </div>
    <DownloadCoordinatesModal v-if="modalDownloadCoordinates.showModal" :modalObject="modalDownloadCoordinates"/>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'
import DownloadCoordinatesModal from '@/views/results/g25/pages/DownloadCoordinatesModal.vue'
import CircleCheckIcon from '@/layouts/icons/CircleCheckIcon.vue'

export default {
  components: {
    DownloadCoordinatesModal,
    CircleCheckIcon,
  },
  data() {
    return {      
      modalDownloadCoordinates: {
        item: null,
        showModal: false,
      },

      kitList: [],
    }
  },
  methods: {
    openUpgradePage() {
      this.$store.state.updateV2.selectedPage = 'UPGRADE';
    },
    openDownloadCoordinateModal() {
      this.modalDownloadCoordinates.showModal = true;
    },

    hasUploadedFile(item) {
      return this.$store.state.updateV2.uploadedFileList.find(x => x.orderResultId === item.id);
    },

    onLoadKitList() {
      ApiService.get('orderresult/old-versions', {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            response.result.forEach(value => {
              value.isUploading = false;
            });
            this.kitList = response.result;
          } else {
            AlertService.error(this, response.message);
          }
        });
    },

    openUpload(fileItem) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      /* eslint-disable*/
      fileInput.addEventListener('change', event => {
        const file = event.target.files[0];

        const fileSizeInMB = file.size / (1024 * 1024); 
        if(fileSizeInMB <= 1) {
          AlertService.warning(this, 'Invalid file size...! File size must be larger than (1MB)');
          return 0;
        }
        
        fileItem.isUploading = true;

        const fileKey = this.generateFileKey();
        ApiService.get(`RawDataFile/presigned-url?fileKey=${fileKey}&contentType=${file.type}`, {}, this)
          .then(response => {
            if (response.statusCode === 200) {
              const presignedUrl = response.result;
              useJwt.axiosIns.put(presignedUrl, file, { headers: { 'Content-Type': file.type } })
                .then(() => {
                  this.$store.state.updateV2.uploadedFileList.push({
                    orderResultId: fileItem.id,
                    rawFileKey: fileKey, 
                    rawFileName: file.name, 
                    rawFileFormat: file.type
                  });
                  
                  fileItem.isUploading = false;
                });
            } else {
              fileItem.isUploading = false;
              AlertService.warning(this, this.$t(response.message));
            }
          });
      })
      fileInput.click();
    },

    generateFileKey() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    },

    startUploading() {
      const updateOrderResults = this.$store.state.updateV2.uploadedFileList;

      if(updateOrderResults.length === 0) {
        AlertService.warning(this, 'Please upload your raw-data file first to proceed with the update.');
        return;
      }

      ApiService.post('orderresult/update-data-version', {updateOrderResults}, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.onLoadKitList();
            AlertService.success(this, this.$t('Your raw-data files have been uploaded. You will be notified when your result is ready'));
          } else {
            AlertService.warning(this, this.$t(response.message));
          }
        });
    },

    deleteUploadedFile(item) {
      this.$store.state.updateV2.uploadedFileList = this.$store.state.updateV2.uploadedFileList.filter(x => x.orderResultId !== item.id);
    },
  },
  computed: {
  },
  mounted() {
    this.onLoadKitList();
  },
};
</script>

<style scoped lang="scss">
.dashboard-card {
  max-width: 800px;
}

.dashboard-card-body {
  max-height: 300px;
  overflow-x: hidden!important;
}

.kit-list {
  padding: 30px;

  .kit-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--lab-hr-color);
    padding: 10px 0px;

    button {
      background-color: #6BD67B !important;
    }

    .file-uploaded {
      padding: 0.54rem 0.85rem;
      background-color: #00adb5;
      border-radius: 0.4rem;
      text-align: center;
      color: #FFFFFF;
      vertical-align: middle;
      font-size: 0.9rem;
      font-weight: 500;
      margin-right: 10px;
      text-align: center;
    }

    .file-delete {
      cursor: pointer;
      color: var(--lab-danger);
      text-decoration: underline;
    }
  }
}

.footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 30px 30px;
}
</style>
