<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <defs>
    </defs>
    <path class="cls-1" d="M11,0C4.93,0,0,4.93,0,11s4.93,11,11,11,11-4.93,11-11S17.07,0,11,0Zm0,19.25c-4.55,0-8.25-3.7-8.25-8.25S6.45,2.75,11,2.75s8.25,3.7,8.25,8.25-3.7,8.25-8.25,8.25Zm5.03-11.9c.54,.54,.54,1.41,0,1.94l-5.35,5.35c-.54,.54-1.41,.54-1.94,0h0s-2.75-2.75-2.75-2.75c-.54-.53-.54-1.41,0-1.94,.53-.54,1.41-.54,1.94,0,0,0,0,0,0,0l1.78,1.78,4.38-4.38c.54-.54,1.41-.54,1.94,0h0Z"/>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>
svg {
  height: 15px;
}  

.cls-1 {
  fill: #fff;
}
</style>
